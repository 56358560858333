<template>
  <div class="section-services" id="services">

<b-container>

<b-col offset-md="1" md="10">

    <div class="section-services-pointer" style="margin-top: 80px;">
      <div class="row">
        <div class="col-12">
          <Pointer></Pointer>
        </div>
      </div>
    </div> <!-- section-services-pointer -->

    <div class="section-services-headline">
      <div class="row">
        <div class="col-12">
          <div class="section-headline"><p style="white-space:pre-wrap;">{{ $t('services.headline') }}</p></div>
        </div>
      </div>
    </div> <!-- section-services-headline -->

</b-col>

</b-container>

    <BackgroundPattern id="section-services-background-pattern-text-work" :text="$t('services.pattern')" align="start"></BackgroundPattern>

    <BackgroundPattern id="section-services-background-pattern-circle-left" type="circle" align="left"></BackgroundPattern>

<b-container>

<b-col offset-md="1" md="10">

    <div class="section-services-title">
      <div class="row">
        <div class="col-12">
          <div class="section-title"><p style="white-space:pre-wrap;">{{ $t('services.title') }}</p></div>
        </div>
      </div>
    </div> <!-- section-services-title -->

    <div class="section-services-list">
      <div class="row">

        <div v-for="d in 3" :key="d" class="col-12 col-md-4">
          <div class="row">
            <div class="col-12">
              <a data-bs-toggle="modal" @click="$bvModal.show($t('services.dialogs[' + (d-1) + '].modal'))" style="cursor: pointer;">
                <img :src="require('../assets'+$t('services.dialogs[' + (d-1) + '].image'))" :srcset="require('../assets'+$t('services.dialogs[' + (d-1) + '].image2x')) + ' 2x, ' + require('../assets'+$t('services.dialogs[' + (d-1) + '].image3x')) + ' 3x'" class="d-block w-100 img-fluid" style="border-radius: 5%;">
              </a>
            </div>
            <div class="col-12">
              <div class="item-heading"><p style="white-space:pre-wrap;">{{ $t('services.dialogs[' + (d-1) + '].heading') }}</p></div>
            </div>
            <div class="col-12">
              <a class="view-more" data-bs-toggle="modal" role="button" @click="$bvModal.show($t('services.dialogs[' + (d-1) + '].modal'))">
                <div><p style="white-space:pre-wrap;">{{ $t('services.dialogs[' + (d-1) + '].view-more') }}</p></div>
              </a>
            </div>
          </div>
        </div>

      </div>
    </div> <!-- section-services-list -->

</b-col>

</b-container>

  </div>
</template>

<script>
import Pointer from './Pointer'
import BackgroundPattern from './BackgroundPattern'

export default {
  name: 'SectionServices',

  components:{
    Pointer,
    BackgroundPattern,
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.section-services-title .section-title {
    margin-top: 200px;
}

.section-services-list .item-heading {
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #50534a;
  margin-top: 12px;
}

.section-services-list .view-more {
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #da291c;
  text-decoration: none;
  margin-top: 12px;
}

.section-services-list .view-more:focus, .section-services-list .view-more:hover {
  color: #da291c;
  text-decoration: none;
}

</style>
