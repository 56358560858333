<template>
  <div class="pointer">

    <div v-if="type === 'short'" class="justify-content-center">
      <div class="pointer-red pointer-red-short"></div>
      <div class="pointer-white-bottom"></div>
    </div>
    <div v-else class="justify-content-center">
      <div class="pointer-red"></div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'Pointer',
  props: {
    type: String,
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.pointer-red::before {
    content:"";
    display: block;
    width: 30px;
    height: 30px;
    margin: 0 0 10px;
    box-shadow: 5px 6px 10px 0 rgba(218, 41, 28, 0.3);
    background-color: #da291c;
    border-radius: 50%;
    margin-left: auto;
    margin-right: auto;
}

.pointer-red::after {
    content:"";
    display: block;
    width: 2px;
    height: 140px;
    margin: 10px 14px 0;
    background-color: #da291c;
    margin-left: auto;
    margin-right: auto;
}
.pointer-red-short::after {
    height: 70px;
}

.pointer-white-bottom {
    background-color: #da291c;
}

.pointer-white-bottom::after {
    content:"";
    display: block;
    width: 2px;
    height: 70px;
    margin: 0px 14px 0;
    background-color: #fff;
    margin-left: auto;
    margin-right: auto;
}
</style>
