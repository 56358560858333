<template>
  <div class="section-client-case">

    <div class="section-client-case-pointer">
      <div class="row">
        <div class="col-12 col-md-6">
          <Pointer></Pointer>
        </div>
        <div class="col-12 col-md-6">
        </div>
      </div>
    </div> <!-- section-client-case-pointer -->

    <div class="section-client-case-title">
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="cc-title"><p style="white-space:pre-wrap;">{{ $t('clientCase.title') }}</p></div>
        </div>
        <div class="col-12 col-md-6">
        </div>
      </div>
    </div> <!-- section-client-case-title -->




        <div class="row">
          <div class="col-12">

            <div class="row">

              <div class="col-12 col-md-3">
<div class="d-none d-lg-block">
                <div class="justify-content-center">
                  <div v-for="(sItem, s) in $i18n.messages.en.clientCase.list" :key="s">
                    <div class="cc-heading2">{{ $t('clientCase.list[' + s + '].text') }}</div>
                    <ol>
                      <li v-for="(oItem, o) in $i18n.messages.en.clientCase.list[s].list" :key="o" class="cc-li">{{ $t('clientCase.list[' + s + '].list[' + o + '].text') }}
                        <ul>
                          <li v-for="(uItem, u) in $i18n.messages.en.clientCase.list[s].list[o].list" :key="u" class="cc-li">{{ $t('clientCase.list[' + s + '].list[' + o + '].list[' + u + '].text') }}</li>
                        </ul>
                      </li>
                    </ol>
                  </div>
                </div>
</div>
              </div>

              <div class="col-12 col-md-9">
                <div class="cc-image">
<!--
                  <img :src="require('../assets'+$t('clientCase.image'))" :srcset="require('../assets'+$t('clientCase.image2x')) + ' 2x, ' + require('../assets'+$t('clientCase.image3x')) + ' 3x'" class="img-fluid">
-->
                  <img :src="require('../assets'+$t('clientCase.image'))" class="img-fluid">
                </div>
              </div>


              <div class="col-12 col-md-3">
<div class="d-block d-lg-none">
                <div class="justify-content-center">
<div class="row">
                  <div v-for="(sItem, s) in $i18n.messages.en.clientCase.list" :key="s" class="col-6">
<div style="margin-left:4px;margin-right:4px;">
                    <div class="cc-heading2">{{ $t('clientCase.list[' + s + '].text') }}</div>
                    <ol>
                      <li v-for="(oItem, o) in $i18n.messages.en.clientCase.list[s].list" :key="o" class="cc-li">{{ $t('clientCase.list[' + s + '].list[' + o + '].text') }}
                        <ul>
                          <li v-for="(uItem, u) in $i18n.messages.en.clientCase.list[s].list[o].list" :key="u" class="cc-li">{{ $t('clientCase.list[' + s + '].list[' + o + '].list[' + u + '].text') }}</li>
                        </ul>
                      </li>
                    </ol>
</div>
                  </div>
</div>
                </div>
</div>
              </div>

            </div> <!-- row -->

          </div>
        </div> <!-- row -->







    <SectionClientCaseLiveChat/>
    <SectionClientCaseNameTag/>
    <SectionClientCaseTgInsertion/>
    <SectionClientCaseLivePoll/>
    <SectionClientCaseLiveQa/>
  </div>
</template>

<script>
import Pointer from './Pointer'
//import BackgroundPattern from './BackgroundPattern'
import SectionClientCaseLiveChat from './SectionClientCaseLiveChat'
import SectionClientCaseNameTag from './SectionClientCaseNameTag'
import SectionClientCaseTgInsertion from './SectionClientCaseTgInsertion'
import SectionClientCaseLivePoll from './SectionClientCaseLivePoll'
import SectionClientCaseLiveQa from './SectionClientCaseLiveQa'

export default {
  name: 'SectionClientCase',

  components:{
    Pointer,
//    BackgroundPattern,
    SectionClientCaseLiveChat,
    SectionClientCaseNameTag,
    SectionClientCaseTgInsertion,
    SectionClientCaseLivePoll,
    SectionClientCaseLiveQa,
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.section-client-case-pointer {
    margin-top:80px;
}
</style>
