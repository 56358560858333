<template>
  <div class="section-contact" id="contact">

<div class="section-contact-pointer" style="margin-top:60px;overflow-x:hidden;">
    <div class="row">
      <div class="col-12">
        <Pointer type="short"></Pointer>
      </div>
    </div>
</div>

<div class="section-contact-lower">

<b-col offset-md="1" md="10">

    <div class="section-contact-title">
      <div class="row">
        <div class="col-12">
          <div class="section-title"><p style="white-space:pre-wrap;">{{ $t('contact.title') }}</p></div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-6">
        <div class="row">
          <div class="col-12">
            <div class="cc-image"><img :src="require('../assets'+$t('contact.phone.image'))"></div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="section-headline"><p style="white-space:pre-wrap;">{{ $t('contact.phone.text') }}</p></div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="row">
          <div class="col-12">
            <div class="cc-image"><img :src="require('../assets'+$t('contact.email.image'))"></div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="section-headline"><p style="white-space:pre-wrap;">{{ $t('contact.email.text') }}</p></div>
          </div>
        </div>
      </div>
    </div>

</b-col>

    </div>

  </div>
</template>

<script>
import Pointer from './Pointer'

export default {
  name: 'SectionContact',

  components:{
    Pointer,
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.section-contact-lower {
    background-color: #da291c;
    padding-bottom:100px;
}

.section-contact-lower .section-title {
    text-align: center;
    color: #fff;
}

.section-contact-lower .section-headline {
    text-align: center;
    color: #fff;
    margin-top:40px;
}

.section-contact-title {
    margin-bottom:40px;
}
.xxx {
  font-size: 32px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: right;
  color: #fff;
}

</style>
