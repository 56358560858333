<template>
  <div class="menu">
    <div class="justify-content-md-center">
      <div class="menu-group">
        <a href="#about" class="menu-group-item menu-group-item-action active" aria-current="true">{{ $t('menu.about') }}</a>
        <a href="#story" class="menu-group-item menu-group-item-action">{{ $t('menu.story') }}</a>
        <a href="#services" class="menu-group-item menu-group-item-action">{{ $t('menu.services') }}</a>
        <a href="#highlights" class="menu-group-item menu-group-item-action">{{ $t('menu.highlights') }}</a>
        <a href="#clients" class="menu-group-item menu-group-item-action">{{ $t('menu.clients') }}</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Menu',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
