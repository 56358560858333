<template>
  <div class="home">

    <Header/>
<!--
    <b-container>
-->
      <SectionAbout/>
      <SectionStory/>
      <SectionServices/>
      <SectionHighlights/>
      <SectionClients/>
<!--
    </b-container>
-->

    <SectionContact/>
    <Footer/>

    <ModalVideoProductions/>
    <ModalLiveStreaming/>
    <ModalItServices/>

  </div>
</template>

<script>
import SectionAbout from '../components/SectionAbout'
import SectionStory from '../components/SectionStory'
import SectionServices from '../components/SectionServices'
import SectionHighlights from '../components/SectionHighlights'
import SectionClients from '../components/SectionClients'
import SectionContact from '../components/SectionContact'
import Header from '../components/Header'
import Footer from '../components/Footer'
import ModalVideoProductions from '../components/ModalVideoProductions'
import ModalLiveStreaming from '../components/ModalLiveStreaming'
import ModalItServices from '../components/ModalItServices'

// @ is an alias to /src
export default {
  name: 'Home',

  components:{
    SectionAbout,
    SectionStory,
    SectionServices,
    SectionHighlights,
    SectionClients,
    SectionContact,
    Header,
    Footer,
    ModalVideoProductions,
    ModalLiveStreaming,
    ModalItServices,
  }
}
</script>
