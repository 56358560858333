<template>
  <div class="section-story" id="story">

<b-container>

<b-col offset-md="1" md="10">

    <div class="section-story-pointer" style="margin-top: 80px;">
      <div class="row">
        <div class="col-12">
          <Pointer></Pointer>
        </div>
      </div>
    </div> <!-- section-story-pointer -->

    <div class="section-story-headline" style="margin-bottom: 80px;">
      <div class="row">
        <div class="col-12">
          <div class="section-headline"><p style="white-space:pre-wrap;">{{ $t('story.headline') }}</p></div>
        </div>
      </div>
    </div> <!-- section-story-headline -->

</b-col>

</b-container>

    <BackgroundPattern id="section-story-background-pattern-text-dingding" :text="$t('story.pattern')" align="end"></BackgroundPattern>

<b-container>

<b-col offset-md="1" md="10">

    <div class="row justify-content-md-center" style="margin-top:180px;">
      <div class="col-12 col-md-6">

        <div class="section-story-image">
          <img src="../assets/img/img-ding-ding.png" srcset="../assets/img/img-ding-ding@2x.png 2x, ../assets/img/img-ding-ding@3x.png 3x" class="img-fluid">
        </div> <!-- section-story-image -->

      </div>
      <div class="col-12 col-md-6">

        <div class="section-story-title">
          <div class="row">
            <div class="col-12">
              <div class="section-title"><p style="white-space:pre-wrap;">{{ $t('story.title') }}</p></div>
            </div>
          </div>
        </div> <!-- section-story-title -->

        <div class="section-story-text">
          <div class="row">
            <div class="col-12">
              <div class="section-text"><p style="white-space:pre-wrap;text-align:justify;text-justify:inter-word;">{{ $t('story.text') }}</p></div>
            </div>
          </div>
        </div> <!-- section-story-text -->

        <div class="section-story-detail">
          <div class="row">
            <div class="col-12">

<!--
     <b-button id="story-detail-collapse-view-detail" size="sm" v-b-toggle="['story-detail-collapse']" class="view-detail">{{ $t('story.view-detail') }}</b-button>
     <b-button id="story-detail-collapse-view-less" size="sm" v-b-toggle="['story-detail-collapse']" class="view-detail view-less" style="display:none;">{{ $t('story.view-less') }}</b-button>

    <b-collapse id="story-detail-collapse" class="mt-2">
      <b-card><p style="white-space:pre-wrap;">{{ $t('story.detail') }}</p></b-card>
    </b-collapse>
-->

            </div>
          </div>
        </div> <!-- section-story-detail -->


      </div>
    </div>

</b-col>

</b-container>

  </div>
</template>

<script>
import Pointer from './Pointer'
import BackgroundPattern from './BackgroundPattern'

export default {
  name: 'SectionStory',

  components:{
    Pointer,
    BackgroundPattern,
  },
  mounted() {
    this.$root.$on('bv::collapse::state', (collapseId, isJustShown) => {
      //console.log('collapseId:', collapseId)
      //console.log('isJustShown:', isJustShown)
      if (isJustShown) {
        document.getElementById('story-detail-collapse-view-detail').style.display="none"
        document.getElementById('story-detail-collapse-view-less').style.display="inline-block"
      } else {
        document.getElementById('story-detail-collapse-view-less').style.display="none"
        document.getElementById('story-detail-collapse-view-detail').style.display="inline-block"
      }
    })
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.section-story-detail .view-detail {
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #da291c;
  text-decoration: none;
  margin-top: 12px;
}

.section-story-detail button.view-detail::before {
    content: "";
    display: inline-flex;
    width: 10px;
    height: 10px;
    margin: 7px 8px 7px 0;
    object-fit: contain;
    background-size: contain;
    background-image: url(../assets/img/glyph-collapse.svg);
}

.section-story-detail button.view-detail {
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #da291c;
  text-decoration: none;
  margin-top: 12px;
    padding: 0;
    border: 0;
  background-color: transparent;
box-shadow: none;
}

.section-story-detail button.view-detail:focus, .section-story-detail button.view-detail:hover {
  text-decoration: none;
  background-color: transparent;
box-shadow: none;
}


.section-story-detail button.view-less::before {
    content: "";
    display: inline-flex;
    width: 10px;
    height: 10px;
    margin: 7px 8px 7px 0;
    object-fit: contain;
    background-size: contain;
    background-image: url(../assets/img/glyph-expand.svg);
}

</style>
