<template>
  <div class="footer">
    <footer class="p-5 text-muted">
<div class="footer-image">
      <div class="float-md-start">
        <img src="../assets/img/company-logo-nagative.svg"/>
      </div>
</div>
<div class="footer-text">
      <div class="align-middle float-md-end">
© 2021 Ding Creative Productions Ltd
      </div>
</div>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'Footer',

  components:{
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.footer {
      background-color: #ae2116;
}
.footer .footer-text {
  margin-top:auto;
  margin-bottom:auto;
  top:0;
  bottom:0;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: justify;
  color: #fff;
}
</style>
