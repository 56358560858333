<template>
  <div class="section-highlights" id="hightlights">

<b-container>

<b-col offset-md="1" md="10">

    <div class="section-highlights-pointer" style="margin-top: 80px;margin-bottom: 40px;">
      <div class="row">
        <BackgroundPattern text="" align="start"></BackgroundPattern>
        <div class="col-12 col-md-6">
        </div>
        <div class="col-12 col-md-6">
          <Pointer></Pointer>
        </div>
      </div>
    </div> <!-- section-highlights-pointer -->

    <div class="row">
      <div class="col-12 col-md-6">

        <div class="section-highlights-title">
          <div class="row">
            <div class="col-12">
              <div class="section-title"><p style="white-space:pre-wrap;">{{ $t('highlights.title') }}</p></div>
            </div>
          </div>
        </div> <!-- section-highlights-title -->





<div v-for="(hItem, h) in $i18n.messages.en.highlights.slides" :key="h" class="section-highlights-slide" :xst-slide-no="h">
        <div class="section-highlights-text">
          <div class="row">
            <div class="col-12">
              <div class="section-text"><p style="white-space:pre-wrap;text-align:justify;text-justify:inter-word;">{{ $t('highlights.slides['+ h +'].text') }}</p></div>
            </div>
          </div>
        </div> <!-- section-highlights-text -->

        <div class="section-highlights-case">
          <div class="row">
            <div class="col-12">
              <div class="section-text"><b><p style="white-space:pre-wrap;">{{ $t('highlights.slides['+ h +'].case-heading') }}</p></b><p style="white-space:pre-wrap;">{{ $t('highlights.slides['+ h +'].case-text') }}</p></div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">

              <a v-if="$t('highlights.slides['+ h +'].case-link')!=''" class="visit-page" :href="$t('highlights.slides['+ h +'].case-link')" target="_blank">
                <div><p style="white-space:pre-wrap;">{{ $t('highlights.slides['+ h +'].visit-page') }}</p></div>
              </a>

            </div>
          </div>

        </div> <!-- section-highlights-case -->
</div>

      </div>
      <div class="col-12 col-md-6">

        <div class="section-highlights-list">





  <b-carousel
    id="carousel-no-animation"
    style="text-shadow: 0px 0px 2px #000"
    no-animation
    indicators
    img-width="1024"
    img-height="480"
    :interval="100000"

    @sliding-start="onSlideStart"
  >

      <b-carousel-slide v-for="(hItem, h) in $i18n.messages.en.highlights.slides" :key="h">
        <template #img>
          <img :src="require('../assets'+$t('highlights.slides['+ h +'].image'))" class="d-block img-fluid w-100" style="border-radius: 5%;">
        </template>
      </b-carousel-slide>

  </b-carousel>









        </div> <!-- section-highlights-list -->

      </div>
    </div>

</b-col>

</b-container>

  </div>
</template>

<script>
import Pointer from './Pointer'
import BackgroundPattern from './BackgroundPattern'

export default {
  name: 'SectionHighlights',

  components:{
    Pointer,
    BackgroundPattern,
  },

  methods:{
      onSlideStart(slide) {
        //console.log('onSlideStart:' + slide)
        //console.log('onSlideStart:' + this.$t('highlights.slides['+ slide +'].case-text'))

        this.highlightsText=this.$t('highlights.slides['+ slide +'].text')
        this.highlightsCaseHeading=this.$t('highlights.slides['+ slide +'].case-heading')
        this.highlightsCaseText=this.$t('highlights.slides['+ slide +'].case-text')
        this.highlightsCaseLink=this.$t('highlights.slides['+ slide +'].case-link')
        this.highlightsVisitPage=this.$t('highlights.slides['+ slide +'].visit-page')



          const items = document.querySelectorAll('.section-highlights .section-highlights-slide')
          items.forEach(function(item) {
           item.style.display = 'none'
          })

          const cItems = document.querySelectorAll('.section-highlights .section-highlights-slide[xst-slide-no="'+slide+'"]')
          cItems.forEach(function(cItem) {
           cItem.style.display = 'block'
          })




      }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>










/deep/ .carousel-indicators {
    position: relative;
}
/deep/ .carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    padding: 0;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity .6s ease;
    width: 12px;
    height: 12px;
    margin: 0 6px;
    background-color: #dddddd;
    border-radius: 50%;

}
/deep/ .carousel-indicators li.active {
    opacity: 1;
    background-color: #da291c;
}

.section-highlights-case .visit-page {
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #da291c;
  text-decoration: none;
  margin-top: 12px;
}


.section-highlights-case .visit-page > div > p::before {
    content: "";
    display: inline-flex;
    width: 10px;
    height: 10px;
    margin: 7px 8px 7px 0;
    object-fit: contain;
    background-size: contain;
    background-image: url(../assets/img/glyph-collapse.svg);
}

</style>
