<template>
  <nav class="nav nav-masthead justify-content-center float-end">


<!--
  <b-dropdown text="EN" variant="outline-danger" no-caret no-flip>
    <b-dropdown-item variant="danger" @click="changeLanguage(this,'en','EN')">EN</b-dropdown-item>
    <b-dropdown-item variant="dark" @click="changeLanguage(this,'tc','繁')">繁</b-dropdown-item>
    <b-dropdown-item variant="dark" @click="changeLanguage(this,'en','简')">简</b-dropdown-item>
  </b-dropdown>
-->

<!--
    <b-form-select v-model="$i18n.locale" :options="options" @change="switchLanguage"></b-form-select>
-->

    <b-form-group v-slot="{ ariaDescribedby }">
      <b-form-radio-group
        id="language-switcher"
        v-model="$i18n.locale"
        :options="options"
        :aria-describedby="ariaDescribedby"
        name="language"
        buttons
 button-variant="outline-danger"
@change="switchLanguage"
      ></b-form-radio-group>
    </b-form-group>

  </nav>
</template>

<script>
export default {

    data() {
      return {
        selected: 'en',
        options: [
          { text: 'EN', value: 'en' },
          { text: '繁', value: 'tc' },
          { text: '简', value: 'sc' }
        ]
      }
    }
,
  methods:{
    switchLanguage(value) {
//      console.log("switchLanguage:"+value)
      localStorage.setItem('language',value)
    }
/*
,
    changeLanguage(obj, value, label) {
      console.log("changeLanguage:"+value+", "+label)
    }
  }
,
  mounted() {
    this.$root.$on('bv::dropdown::show', bvEvent => {
      console.log('Dropdown is about to be shown', bvEvent)
    })
*/
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/deep/ #language-switcher input {
    display: none;
}
</style>

