<template>
  <div class="section-client-case-tg-insertion">

    <div class="section-client-case-tg-insertion-pointer">
      <div class="row">
        <div class="col-12">
          <Pointer></Pointer>
        </div>
      </div>
    </div> <!-- section-client-case-tg-insertion-pointer -->

    <div class="section-client-case-tg-insertion-title">
      <div class="row">
        <div class="col-12">
          <div class="cc-title"><p style="white-space:pre-wrap;">{{ $t('clientCaseTgInsertion.title') }}</p></div>
        </div>
      </div>
    </div> <!-- section-client-case-tg-insertion-title -->

    <div class="section-client-case-tg-insertion-headline">
      <div class="row">
        <div class="col-12">
          <div class="cc-headline"><p style="white-space:pre-wrap;">{{ $t('clientCaseTgInsertion.headline') }}</p></div>
        </div>
      </div>
    </div> <!-- section-client-case-tg-insertion-headline -->

    <div class="section-client-case-tg-insertion-heading">
      <div class="row">
        <div class="col-12">
          <div class="cc-heading"><p style="white-space:pre-wrap;">{{ $t('clientCaseTgInsertion.heading') }}</p></div>
        </div>
      </div>
    </div> <!-- section-client-case-tg-insertion-heading -->

    <div class="section-client-case-tg-insertion-text">
      <div class="row">
        <div class="col-12">
          <div class="cc-text"><p style="white-space:pre-wrap;text-align:justify;text-justify:inter-word;">{{ $t('clientCaseTgInsertion.text') }}</p></div>
        </div>
      </div>
    </div> <!-- section-client-case-tg-insertion-text -->

    <div class="section-client-case-tg-insertion-image">
      <div class="row">
        <div class="col-12">
          <div class="cc-image">
<img :src="require('../assets'+$t('clientCaseTgInsertion.image'))" :srcset="require('../assets'+$t('clientCaseTgInsertion.image2x')) + ' 2x, ' + require('../assets'+$t('clientCaseTgInsertion.image3x')) + ' 3x'" class="img-fluid">
          </div>
        </div>
      </div>
    </div> <!-- section-client-case-tg-insertion-image -->

  </div>
</template>

<script>
import Pointer from './Pointer'
//import BackgroundPattern from './BackgroundPattern'

export default {
  name: 'SectionClientCaseTgInsertion',

  components:{
    Pointer,
//    BackgroundPattern,
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.section-client-case-tg-insertion-pointer {
    margin-top:40px;
}
</style>
