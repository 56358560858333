<template>
  <div class="background-pattern">
    <div class="background-text-container">

      <div v-if="type === 'circle' && align === 'left'" class="background-text text-start half-circle-left">
      </div>
      <div v-if="type === 'circle' && align === 'right'" class="background-text text-end half-circle-right">
      </div>
      <div v-if="type === 'donut' && align === 'right'" class="background-text text-end half-donut-right">
      </div>
      <div v-else-if="align === 'end'" class="background-text text-end">
        <p style="white-space:pre-wrap;">{{ text }}</p>
      </div>
      <div v-else class="background-text text-start">
        <p style="white-space:pre-wrap;">{{ text }}</p>
      </div>

    </div>
  </div>
</template>

<script>

export default {
  name: 'BackgroundPattern',
  props: {
    type: String,
    text: String,
    align: String,
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>



.circle {
    background-image: url("../assets/img/img-bg-oval.svg");
    background-repeat: no-repeat;
    background-position: -250px 0px;
/*
    background-size: 50%;
*/
}



.outline-circle {
    background-image: url("../assets/img/img-bg-outline-oval.svg");
    background-repeat: no-repeat;
    background-position: -250px 0px;
/*
    background-size: 50%;
*/
}



.background-text-container {
    position: relative;
/*
    overflow: hidden;
*/
}

.background-text {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
    overflow: hidden;
    height: fit-content;
    font-size: 185px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #f2f2f2;
}



</style>
