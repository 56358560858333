<template>
  <div class="section-client-case-name-tag">

    <div class="section-client-case-name-tag-pointer">
      <div class="row">
        <div class="col-12">
          <Pointer></Pointer>
        </div>
      </div>
    </div> <!-- section-client-case-name-tag-pointer -->

    <div class="section-client-case-name-tag-title">
      <div class="row">
        <div class="col-12">
          <div class="cc-title"><p style="white-space:pre-wrap;">{{ $t('clientCaseNameTag.title') }}</p></div>
        </div>
      </div>
    </div> <!-- section-client-case-name-tag-title -->

    <div class="section-client-case-name-tag-headline">
      <div class="row">
        <div class="col-12">
          <div class="cc-headline"><p style="white-space:pre-wrap;">{{ $t('clientCaseNameTag.headline') }}</p></div>
        </div>
      </div>
    </div> <!-- section-client-case-name-tag-headline -->

    <div class="section-client-case-name-tag-heading">
      <div class="row">
        <div class="col-12">
          <div class="cc-heading"><p style="white-space:pre-wrap;">{{ $t('clientCaseNameTag.heading') }}</p></div>
        </div>
      </div>
    </div> <!-- section-client-case-name-tag-heading -->

    <div class="section-client-case-name-tag-text">
      <div class="row">
        <div class="col-12">
          <div class="cc-text"><p style="white-space:pre-wrap;text-align:justify;text-justify:inter-word;">{{ $t('clientCaseNameTag.text') }}</p></div>
        </div>
      </div>
    </div> <!-- section-client-case-name-tag-text -->

    <div class="section-client-case-name-tag-image">
      <div class="row">
        <div class="col-12">
          <div class="cc-image">
<img :src="require('../assets'+$t('clientCaseNameTag.image'))" :srcset="require('../assets'+$t('clientCaseNameTag.image2x')) + ' 2x, ' + require('../assets'+$t('clientCaseNameTag.image3x')) + ' 3x'" class="img-fluid">
          </div>
        </div>
      </div>
    </div> <!-- section-client-case-name-tag-image -->

  </div>
</template>

<script>
import Pointer from './Pointer'
//import BackgroundPattern from './BackgroundPattern'

export default {
  name: 'SectionClientCaseNameTag',

  components:{
    Pointer,
//    BackgroundPattern,
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.section-client-case-name-tag-pointer {
    margin-top:40px;
}
</style>
