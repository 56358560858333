<template>
  <b-modal id="modal-video-productions" size="xl" scrollable hide-footer NOT_hide-header>
    <template #modal-header="{ close }">
      <p></p>
      <b-button size="sm" variant="outline-danger" @click="close()">{{ $t('close') }}</b-button>
    </template>

    <BackgroundPattern id="modal-video-productions-background-pattern-circle-right" type="circle" align="right"></BackgroundPattern>

<b-container>

<b-col offset-md="1" md="10">

      <div class="section-video-productions-title">
        <div class="row">
          <div class="col-12">
            <div class="section-title"><p style="white-space:pre-wrap;">{{ $t('modalVideoProductions.title') }}</p></div>
          </div>
        </div>
      </div> <!-- section-video-productions-title -->

      <div class="section-video-productions-text">
        <div class="row">
          <div class="col-12">
            <div class="section-text"><p style="white-space:pre-wrap;text-align:justify;text-justify:inter-word;">{{ $t('modalVideoProductions.text') }}</p></div>
          </div>
        </div>
      </div> <!-- section-video-productions-title -->

      <div class="section-video-productions-pointer">
        <div class="row">
          <div class="col-12">
            <Pointer/>
          </div>
        </div>
      </div> <!-- section-video-productions-title -->

      <div class="section-video-productions-headline">
        <div class="row">
          <div class="col-12">
            <div class="section-headline"><p style="white-space:pre-wrap;">{{ $t('modalVideoProductions.headline') }}</p></div>
          </div>
        </div>
      </div> <!-- section-video-productions-title -->

</b-col>

</b-container>

      <div v-for="(section, s) in $i18n.messages.en.modalVideoProductions.sections" :key="s" class="section-video-productions-list">

        <BackgroundPattern v-if="s==0" id="modal-video-production-background-pattern-text-crew" :text="$t('modalVideoProductions.sections[' + s + '].pattern')"/>
        <BackgroundPattern v-else-if="s==1" id="modal-video-production-background-pattern-text-creative" :text="$t('modalVideoProductions.sections[' + s + '].pattern')" align="end"/>
        <BackgroundPattern v-else-if="s==2" id="modal-video-production-background-pattern-text-equipment" :text="$t('modalVideoProductions.sections[' + s + '].pattern')"/>
        <BackgroundPattern v-else :text="$t('modalVideoProductions.sections[' + s + '].pattern')"/>


<b-container>

<b-col offset-md="1" md="10">

        <div class="row">
          <div class="col-12">

            <div v-for="(item, i) in $i18n.messages.en.modalVideoProductions.sections[s].list" :key="i" class="row">
              <div class="col">

                <div class="d-none d-lg-block">
                  <div class="d-flex flex-row icon-list" :xst-grid-section-no="'lg-' + s" :xst-section-no="s" :xst-item-no="s+'-'+i">
                    <div><img :src="require('../assets'+$t('modalVideoProductions.sections[' + s + '].list[' + i + '].image'))" class="icon-list-glyph"></div>
                    <div class="align-middle" style="margin-top: auto;margin-bottom: auto;">
                      <div class="icon-list-title">{{ $t('modalVideoProductions.sections[' + s + '].list[' + i + '].title') }}</div>
                      <div class="icon-list-text">{{ $t('modalVideoProductions.sections[' + s + '].list[' + i + '].text') }}</div>
                    </div>
                  </div>
                </div>

                <div class="d-block d-lg-none">
                  <div class="icon-list" :xst-grid-section-no="'md-' + s" :xst-section-no="s" :xst-item-no="s+'-'+i">
                    <div class="row">
                      <div class="col">
                        <div style="text-align: center;margin-left: auto;margin-right: auto;"><img :src="require('../assets'+$t('modalVideoProductions.sections[' + s + '].list[' + i + '].image'))" class="icon-list-glyph"></div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <div style="text-align: center;margin-left: auto;margin-right: auto;" class="icon-list-title">{{ $t('modalVideoProductions.sections[' + s + '].list[' + i + '].title') }}</div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <div style="text-align: center;margin-left: auto;margin-right: auto;" class="icon-list-text">{{ $t('modalVideoProductions.sections[' + s + '].list[' + i + '].text') }}</div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>

          </div>
        </div>

</b-col>

</b-container>

      </div> <!-- section-video-productions-list -->

  </b-modal>
</template>

<script>
import Pointer from './Pointer'
import BackgroundPattern from './BackgroundPattern'

export default {
  name: 'ModalVideoProductions',

  components:{
    Pointer,
    BackgroundPattern,
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.section-video-productions-list .icon-list[xst-grid-section-no="lg-1"] {
    margin-left: 160px;
}
.section-video-productions-list .icon-list[xst-item-no="0-0"], .section-video-productions-list .icon-list[xst-item-no="1-0"], .section-video-productions-list .icon-list[xst-item-no="2-0"] {
    margin-top: 120px;
}
/deep/ .modal-body {
    z-index:0!important;
    background-color: #f9f9f9;
    padding:0;
}
/deep/ .modal-header {
    background-color: #f9f9f9;
}
</style>
