<template>
  <div class="section-client-case-live-qa">

    <div class="section-client-case-live-qa-pointer">
      <div class="row">
        <div class="col-12">
          <Pointer></Pointer>
        </div>
      </div>
    </div> <!-- section-client-case-live-qa-pointer -->

    <div class="section-client-case-live-qa-title">
      <div class="row">
        <div class="col-12">
          <div class="cc-title"><p style="white-space:pre-wrap;">{{ $t('clientCaseLiveQa.title') }}</p></div>
        </div>
      </div>
    </div> <!-- section-client-case-live-qa-title -->

    <div class="section-client-case-live-qa-headline">
      <div class="row">
        <div class="col-12">
          <div class="cc-headline"><p style="white-space:pre-wrap;">{{ $t('clientCaseLiveQa.headline') }}</p></div>
        </div>
      </div>
    </div> <!-- section-client-case-live-qa-headline -->

    <div class="section-client-case-live-qa-heading">
      <div class="row">
        <div class="col-12">
          <div class="cc-heading"><p style="white-space:pre-wrap;">{{ $t('clientCaseLiveQa.heading') }}</p></div>
        </div>
      </div>
    </div> <!-- section-client-case-live-qa-heading -->

    <div class="section-client-case-live-qa-text">
      <div class="row">
        <div class="col-12">
          <div class="cc-text"><p style="white-space:pre-wrap;text-align:justify;text-justify:inter-word;">{{ $t('clientCaseLiveQa.text') }}</p></div>
        </div>
      </div>
    </div> <!-- section-client-case-live-qa-text -->

    <div class="section-client-case-live-qa-image">
      <div class="row">
        <div class="col-12">
          <div class="cc-image">
<img :src="require('../assets'+$t('clientCaseLiveQa.image'))" :srcset="require('../assets'+$t('clientCaseLiveQa.image2x')) + ' 2x, ' + require('../assets'+$t('clientCaseLiveQa.image3x')) + ' 3x'" class="img-fluid">
          </div>
        </div>
      </div>
    </div> <!-- section-client-case-live-qa-image -->

  </div>
</template>

<script>
import Pointer from './Pointer'
//import BackgroundPattern from './BackgroundPattern'

export default {
  name: 'SectionClientCaseLiveQa',

  components:{
    Pointer,
//    BackgroundPattern,
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.section-client-case-live-qa-pointer {
    margin-top:40px;
}
</style>
