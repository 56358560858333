<template>
<div class="col-xxl-12 mx-auto p-3">
  <div class="row">
    <div class="col-12">
      <div class="header">
      <header class="mb-auto">
      <div>
        <h3 class="company-logo float-start mb-0">
          <img class="img-fluid" src="../assets/img/company-logo.svg"/>
        </h3>
        <LanguageSwitcher/>
      </div>
      </header>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import LanguageSwitcher from './LanguageSwitcher'

export default {
  name: 'Header',

  components:{
    LanguageSwitcher,
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
