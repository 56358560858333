<template>
  <b-modal id="modal-it-services" size="xl" scrollable hide-footer NOT_hide-header>
    <template #modal-header="{ close }">
      <p></p>
      <b-button size="sm" variant="outline-danger" @click="close()">{{ $t('close') }}</b-button>
    </template>

<b-container>

<b-col offset-md="1" md="10">

      <div class="section-it-services-title">
        <div class="row">
          <div class="col-12">
            <div class="section-title"><p style="white-space:pre-wrap;">{{ $t('modalItServices.title') }}</p></div>
          </div>
        </div>
      </div> <!-- section-live-streaming-title -->

      <div class="section-it-services-image">
        <div class="row">
          <div class="col-12">
            <div class="cc-image">
              <img :src="require('../assets'+$t('modalItServices.image'))" class="img-fluid">
            </div>
          </div>
        </div>
      </div> <!-- section-it-services-image -->







        <div class="row" style="display:none;">
          <div class="col">



            <div class="row">
              <div class="col" style="margin:72px">

                <div class="row">
                  <div class="col">
                    <div style="margin-left:auto;margin-right:auto;width: fit-content; width: -moz-fit-content;">
                      <img src="../assets/img/glyph-video.svg" style="width:48px;"/>
                    </div>
                  </div>
                </div> <!-- row -->
                <div class="row">
                  <div class="col">
                    <div class="section-heading2">Video Platform Chat / Sharing Interactivity</div>
                  </div>
                </div> <!-- row -->

              </div>
              <div class="col" style="margin:72px">

                <div class="row">
                  <div class="col">
                    <div style="margin-left:auto;margin-right:auto;width: fit-content; width: -moz-fit-content;">
                      <img src="../assets/img/glyph-development.svg" style="width:48px;"/>
                    </div>
                  </div>
                </div> <!-- row -->
                <div class="row">
                  <div class="col">
                    <div class="section-heading2">Website, APPs, Software Development</div>
                  </div>
                </div> <!-- row -->

              </div>
            </div> <!-- row -->



            <div class="row">
              <div class="col" style="margin:72px">

                <div class="row">
                  <div class="col">
                    <div style="margin-left:auto;margin-right:auto;width: fit-content; width: -moz-fit-content;">
                      <img src="../assets/img/glyph-payment.svg" style="width:48px;"/>
                    </div>
                  </div>
                </div> <!-- row -->
                <div class="row">
                  <div class="col">
                    <div class="section-heading2">Online Ticketing Payment Gateway Charging Models</div>
                  </div>
                </div> <!-- row -->

              </div>
              <div class="col" style="margin:72px">

                <div class="row">
                  <div class="col">
                    <div style="margin-left:auto;margin-right:auto;width: fit-content; width: -moz-fit-content;">
                      <img src="../assets/img/glyph-cloud.svg" style="width:48px;"/>
                    </div>
                  </div>
                </div> <!-- row -->
                <div class="row">
                  <div class="col">
                    <div class="section-heading2">Cyber Security, Cloud Services, IT Consultancy</div>
                  </div>
                </div> <!-- row -->

              </div>
            </div> <!-- row -->

          </div>
        </div> <!-- row -->












</b-col>

</b-container>

  </b-modal>
</template>

<script>
//import Pointer from './Pointer'
//import BackgroundPattern from './BackgroundPattern'

export default {
  name: 'ModalItServices',

  components:{
//    Pointer,
//    BackgroundPattern,
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/deep/ .modal-body {
    z-index:0!important;
    background-color: #f9f9f9;
    padding:0;
}
/deep/ .modal-header {
    background-color: #f9f9f9;
}
</style>
