<template>
  <div class="media-filter">


  <div class="media-filter-client">

    <b-form-group v-slot="{ ariaDescribedby }">


      <b-form-radio
        v-model="selected"
        key="all"
        value='all'
        :aria-describedby="ariaDescribedby"
        name="flavour-4a"
        inline
        @change="selectClient"
      >
<span>All</span>
      </b-form-radio>




<!--
      <b-form-radio
        v-for="option in options"
        v-model="selected"
        :key="option.value"
        :value="option.value"
        :aria-describedby="ariaDescribedby"
        name="flavour-4a"
        inline
        @change="selectClient"
      >
<span><img v-if="containsKey(option, 'image')" style="width:32px; height:32px;" :src="require('../assets'+option.image)"/> {{ option.text }}</span>
      </b-form-radio>

-->



      <b-form-radio
        v-for="(client, c) in $i18n.messages.en.clients.filters"
        v-model="selected"
        :key="$t('clients.filters[' + c + '].value')"
        :value="$t('clients.filters[' + c + '].value')"
        :aria-describedby="ariaDescribedby"
        name="flavour-4a"
        inline
        @change="selectClient"
        :xst-client-no="c"
      >
        <span v-if="c==1">
          <img v-if="containsKey(client, 'image')" style="height:32px;" :src="require('../assets'+$t('clients.filters[' + c + '].image'))"/>
        </span>
        <span v-else-if="c==3">
          <img v-if="containsKey(client, 'image')" style="width:128px; height:16px;" :src="require('../assets'+$t('clients.filters[' + c + '].image'))"/>
        </span>
        <span v-else>
          <img v-if="containsKey(client, 'image')" style="width:32px; height:32px;" :src="require('../assets'+$t('clients.filters[' + c + '].image'))"/>
          <span v-if="containsKey(client, 'name')"> {{ $t('clients.filters[' + c + '].name') }}</span>
        </span>

      </b-form-radio>

    </b-form-group>

  </div>



<div :class="'media-filter-event media-filter-event-'+$t('clients.filters[' + c + '].value')"
  v-for="(client, c) in $i18n.messages.en.clients.filters"
  :key="c"
  :xst-client-no="c"
  :xst-client-key="$t('clients.filters[' + c + '].value')"
>

    <b-form-group v-slot="{ ariaDescribedby }" v-if="$i18n.messages.en.clients.filters[c].events.length">

      <b-form-radio
        v-model="selected"
        :key="$t('clients.filters[' + c + '].value')"
        :value="$t('clients.filters[' + c + '].value')"
        :aria-describedby="ariaDescribedby"
        name="flavour-4a"
        inline
        @change="selectEvent"
      >
<span>All</span>
      </b-form-radio>

      <b-form-radio
        v-for="(event, e) in $i18n.messages.en.clients.filters[c].events"
        v-model="selected"
        :key="$t('clients.filters[' + c + '].events[' + e + '].value')"
        :value="$t('clients.filters[' + c + '].events[' + e + '].value')"
        :aria-describedby="ariaDescribedby"
        name="flavour-4a"
        inline
        @change="selectEvent"
        :xst-event-no="c+'-'+e"
      >
<span>{{ $t('clients.filters[' + c + '].events[' + e + '].name') }}</span>
      </b-form-radio>

    </b-form-group>

</div>


  </div>
</template>

<script>
  export default {
    data() {
      return {
        selected: 'all',

        checkedAll: true,
        checkedSelflab: false,
        checkedMvs: false,

        checked: ['selflab','mvs'], // Must be an array reference!
        options: [
          { text:'SELF LAB', value:'selflab', image:'/img/logo-selflab.svg' },
          { text:'MVS', value:'mvs', image:'/img/logo-mvs.svg' }
        ]
      }
    },
    methods: {
        containsKey(obj, key ) {
            return Object.keys(obj).includes(key);
        },
      selectClient(checked) {
        console.log('Client:'+checked)

        if (checked == 'all') {
          const items = document.querySelectorAll('.section-clients-media .item')
          items.forEach(function(item) {
           item.style.display = 'block'
          })

          const eventFilters = document.querySelectorAll('.media-filter-event')
          eventFilters.forEach(function(eventFilter) {
           eventFilter.style.display = 'none'
          })

        } else {

          const items = document.querySelectorAll('.section-clients-media .item')
          items.forEach(function(item) {
           item.style.display = 'none'
          })

            const cItems = document.querySelectorAll('.section-clients-media .client-'+checked)
            cItems.forEach(function(cItem) {
             cItem.style.display = 'block'
            })


          const eventFilters = document.querySelectorAll('.media-filter-event')
          eventFilters.forEach(function(eventFilter) {
           eventFilter.style.display = 'none'
          })
            const cEventFilters = document.querySelectorAll('.media-filter-event-'+checked)
            cEventFilters.forEach(function(cEventFilter) {
             cEventFilter.style.display = 'block'
            })



        }

      },
      selectEvent(checked) {
        console.log('Event:'+checked)

          const items = document.querySelectorAll('.section-clients-media .item')
          items.forEach(function(item) {
           item.style.display = 'none'
          })

            const cItems = document.querySelectorAll('.section-clients-media .client-'+checked)
            cItems.forEach(function(cItem) {
             cItem.style.display = 'block'
            })


      },
      changeOptions(checked) {
        if (checked.length==this.options.length) {
          console.log('change all to checked')
          this.checkedAll=true
        } else {
          console.log('change all to unchecked')
          this.checkedAll=false
        }
        console.log(checked)
        console.log(this.options)


          const items = document.querySelectorAll('.section-clients-media .item')
          items.forEach(function(item) {
           item.style.display = 'none'
          })

          checked.forEach(function(c) {
           console.log(c)

            const cItems = document.querySelectorAll('.section-clients-media .client-'+c)
            cItems.forEach(function(cItem) {
             cItem.style.display = 'block'
            })
          })

      },
      toggleAll(checked) {

        if (checked=="true") {
          console.log('change others to checked')
          this.checked.splice(0, this.options.length)
          this.checked=['selflab','mvs']
          const items = document.querySelectorAll('.section-clients-media .item')
          items.forEach(function(item) {
           item.style.display = 'block'
          })
        } else {
          this.checked.splice(0, this.options.length)
          console.log('change others to unchecked')
          const items = document.querySelectorAll('.section-clients-media .item')
          items.forEach(function(item) {
           item.style.display = 'none'
          })
        }
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.media-filter-event {
  display:none;
}
</style>
