<template>
  <b-modal id="modal-live-streaming" size="xl" scrollable hide-footer NOT_hide-header>
    <template #modal-header="{ close }">
      <p></p>
      <b-button size="sm" variant="outline-danger" @click="close()">{{ $t('close') }}</b-button>
    </template>

    <BackgroundPattern id="modal-live-streaming-background-pattern-donut-right" type="donut" align="right"></BackgroundPattern>

<b-container>

<b-col offset-md="1" md="10">

      <div class="section-live-streaming-title">
        <div class="row">
          <div class="col-12">
            <div class="section-title"><p style="white-space:pre-wrap;">{{ $t('modalLiveStreaming.title') }}</p></div>
          </div>
        </div>
      </div> <!-- section-live-streaming-title -->

      <div class="section-live-streaming-heading">
        <div class="row">
          <div class="col-12">
            <div class="section-heading"><p style="white-space:pre-wrap;">{{ $t('modalLiveStreaming.heading') }}</p></div>
          </div>
        </div>
      </div> <!-- section-live-streaming-heading -->

      <div class="section-live-streaming-text">
        <div class="row">
          <div class="col-12">
            <div class="section-text"><p style="white-space:pre-wrap;text-align:justify;text-justify:inter-word;">{{ $t('modalLiveStreaming.text') }}</p></div>
          </div>
        </div>
      </div> <!-- section-live-streaming-text -->

      <div class="section-live-streaming-heading2">
        <div class="row">
          <div class="col-12">
            <div class="section-heading"><p style="white-space:pre-wrap;">{{ $t('modalLiveStreaming.heading2') }}</p></div>
          </div>
        </div>
      </div> <!-- section-live-streaming-heading -->

      <div class="section-live-streaming-text2">
        <div class="row">
          <div class="col-12">
            <div class="section-text"><p style="white-space:pre-wrap;text-align:justify;text-justify:inter-word;">{{ $t('modalLiveStreaming.text2') }}</p></div>
          </div>
        </div>
      </div> <!-- section-live-streaming-text -->

      <div class="section-live-streaming-pointer">
        <div class="row">
          <div class="col-12">
            <Pointer/>
          </div>
        </div>
      </div> <!-- section-live-streaming-pointer -->

      <div class="section-live-streaming-headline">
        <div class="row">
          <div class="col-12">
            <div class="section-headline"><p style="white-space:pre-wrap;">{{ $t('modalLiveStreaming.headline') }}</p></div>
          </div>
        </div>
      </div> <!-- section-live-streaming-headline -->

</b-col>

</b-container>





      <div class="section-live-streaming-event">
        <BackgroundPattern id="modal-live-streaming-background-pattern-text-events" :text="$t('modalLiveStreaming.event.pattern')" align="start"/>

<b-container>

<b-col offset-md="1" md="10">

        <div v-for="(stage, s) in $i18n.messages.en.modalLiveStreaming.event.stages" :key="s" class="row">
          <div class="col-12">
            <div class="d-flex flex-row event-stage" :xst-stage-no="s">

              <div class="d-none d-lg-block">
                <div class="event-stage-title">{{ $t('modalLiveStreaming.event.stages[' + s + '].title') }}</div>
              </div>

              <div class="event-stage-list" style="width:100%">

                <div class="d-block d-lg-none">
                  <div style="text-align: center;margin-left: auto;margin-right: auto;" class="event-stage-title">{{ $t('modalLiveStreaming.event.stages[' + s + '].title') }}</div>
                </div>

                <div v-for="(item, i) in $i18n.messages.en.modalLiveStreaming.event.stages[s].list" :key="i" class="event-stage-list-item">

                  <div class="d-none d-lg-block">
                    <div class="event-stage-list-item-title">{{ $t('modalLiveStreaming.event.stages[' + s + '].list[' + i + '].title') }}</div>
                    <div class="event-stage-list-item-text">{{ $t('modalLiveStreaming.event.stages[' + s + '].list[' + i + '].text') }}</div>
                  </div>


                  <div class="d-block d-lg-none">
                    <div class="event-stage-list-item-text">{{ $t('modalLiveStreaming.event.stages[' + s + '].list[' + i + '].title') }} {{ $t('modalLiveStreaming.event.stages[' + s + '].list[' + i + '].text') }}</div>
                  </div>

                </div>
                <div style="margin-top: 12px;padding-top: 14px;">
                  <a class="view-detail" data-bs-toggle="modal" href="" role="button">{{ $t('modalLiveStreaming.event.stages[' + s + '].view-detail') }}</a>
                </div>
              </div>
            </div> <!-- event-stage -->
          </div>
        </div>

</b-col>

</b-container>

      </div> <!-- section-live-streaming-event -->

      <div class="section-live-streaming-pointer">
        <div class="row">
          <div class="col-12">
            <Pointer/>
          </div>
        </div>
      </div> <!-- section-live-streaming-pointer -->

      <div v-for="(section, s) in $i18n.messages.en.modalLiveStreaming.sections" :key="s" class="section-live-streaming-list">
        <BackgroundPattern v-if="s==0" id="modal-live-streaming-background-pattern-text-general" :text="$t('modalLiveStreaming.sections[' + s + '].pattern')"/>
        <BackgroundPattern v-else-if="s==1" id="modal-live-streaming-background-pattern-text-interact" :text="$t('modalLiveStreaming.sections[' + s + '].pattern')" align="end"/>
        <BackgroundPattern v-else-if="s==2" id="modal-live-streaming-background-pattern-text-support" :text="$t('modalLiveStreaming.sections[' + s + '].pattern')"/>
        <BackgroundPattern v-else :text="$t('modalLiveStreaming.sections[' + s + '].pattern')"/>

<b-container>

<b-col offset-md="1" md="10">

        <div class="row">
          <div class="col-12">

            <div v-for="(item, i) in $i18n.messages.en.modalLiveStreaming.sections[s].list" :key="i" class="row">
              <div class="col">

                <div class="d-none d-lg-block">
                  <div class="d-flex flex-row icon-list" :xst-grid-section-no="'lg-' + s" :xst-section-no="s" :xst-item-no="s + '-' + i">
                    <div><img :src="require('../assets'+$t('modalLiveStreaming.sections[' + s + '].list[' + i + '].image'))" class="icon-list-glyph"></div>
                    <div class="align-middle" style="margin-top: auto;margin-bottom: auto;">
                      <div class="icon-list-title">{{ $t('modalLiveStreaming.sections[' + s + '].list[' + i + '].title') }}</div>
                      <div class="icon-list-text">{{ $t('modalLiveStreaming.sections[' + s + '].list[' + i + '].text') }}</div>
                    </div>
                  </div>
                </div>

                <div class="d-block d-lg-none">
                  <div class="icon-list" :xst-grid-section-no="'md-' + s" :xst-section-no="s" :xst-item-no="s+'-'+i">
                    <div class="row">
                      <div class="col">
                        <div style="text-align: center;margin-left: auto;margin-right: auto;"><img :src="require('../assets'+$t('modalLiveStreaming.sections[' + s + '].list[' + i + '].image'))" class="icon-list-glyph"></div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <div style="text-align: center;margin-left: auto;margin-right: auto;">
                          <div class="icon-list-title">{{ $t('modalLiveStreaming.sections[' + s + '].list[' + i + '].title') }}</div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <div style="text-align: center;margin-left: auto;margin-right: auto;">
                          <div class="icon-list-text">{{ $t('modalLiveStreaming.sections[' + s + '].list[' + i + '].text') }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>

          </div>
        </div>

</b-col>

</b-container>

      </div> <!-- section-live-streaming-list -->

<b-container>

<b-col offset-md="1" md="10">

      <SectionClientCase/>

</b-col>

</b-container>

  </b-modal>
</template>

<script>
import Pointer from './Pointer'
import BackgroundPattern from './BackgroundPattern'
import SectionClientCase from './SectionClientCase'

export default {
  name: 'ModalLiveStreaming',

  components:{
    Pointer,
    BackgroundPattern,
    SectionClientCase,
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.section-live-streaming-pointer {
    margin-top: 60px;
}


.section-live-streaming-list .icon-list[xst-item-no="0-0"], .section-live-streaming-list .icon-list[xst-item-no="1-0"], .section-live-streaming-list .icon-list[xst-item-no="2-0"] {
    margin-top: 120px;
}

.section-live-streaming-list .icon-list[xst-grid-section-no="lg-1"] {
    margin-left: 160px;
}

.event-stage[xst-stage-no="0"] {
    margin-top: 150px;
}


/deep/ .modal-body {
    z-index:0!important;
    background-color: #f9f9f9;
    padding:0;
}
/deep/ .modal-header {
    background-color: #f9f9f9;
}
</style>
