<template>
  <div class="section-about" id="about">

<b-container>

<b-col offset-md="1" md="10">

    <div class="section-about-title" style="margin-top: 200px;">
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="section-title"><p style="white-space:pre-wrap;">{{ $t('about.title') }}</p></div>
        </div>
      </div>
    </div> <!-- section-about-title -->

    <div class="section-about-heading">
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="section-heading"><p style="white-space:pre-wrap;">{{ $t('about.heading') }}</p></div>
        </div>
      </div>
    </div> <!-- section-about-heading -->

    <div class="section-about-text">
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="section-text"><p style="white-space:pre-wrap;text-align:justify;text-justify:inter-word;">{{ $t('about.text') }}</p></div>
        </div>
        <div class="col-12 col-md-6">
          <Menu></Menu>
        </div>
      </div>
    </div> <!-- section-about-text -->

</b-col>

</b-container>

  </div>
</template>

<script>
import Menu from './Menu'

export default {
  name: 'SectionAbout',

  components:{
    Menu,
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
