<template>
  <div class="section-client-case-live-chat">

    <div class="section-client-case-live-chat-pointer">
      <div class="row">
        <div class="col-12">
          <Pointer></Pointer>
        </div>
      </div>
    </div> <!-- section-client-case-live-chat-pointer -->

    <div class="section-client-case-live-chat-title">
      <div class="row">
        <div class="col-12">
          <div class="cc-title"><p style="white-space:pre-wrap;">{{ $t('clientCaseLiveChat.title') }}</p></div>
        </div>
      </div>
    </div> <!-- section-client-case-live-chat-title -->

    <div class="section-client-case-live-chat-headline">
      <div class="row">
        <div class="col-12">
          <div class="cc-headline"><p style="white-space:pre-wrap;">{{ $t('clientCaseLiveChat.headline') }}</p></div>
        </div>
      </div>
    </div> <!-- section-client-case-live-chat-headline -->

    <div class="section-client-case-live-chat-heading">
      <div class="row">
        <div class="col-12">
          <div class="cc-heading"><p style="white-space:pre-wrap;">{{ $t('clientCaseLiveChat.heading') }}</p></div>
        </div>
      </div>
    </div> <!-- section-client-case-live-chat-heading -->

    <div class="section-client-case-live-chat-text">
      <div class="row">
        <div class="col-12">
          <div class="cc-text"><p style="white-space:pre-wrap;text-align:justify;text-justify:inter-word;">{{ $t('clientCaseLiveChat.text') }}</p></div>
        </div>
      </div>
    </div> <!-- section-client-case-live-chat-text -->

    <div class="section-client-case-live-chat-heading2">
      <div class="row">
        <div class="col-12">
          <div class="cc-heading"><p style="white-space:pre-wrap;">{{ $t('clientCaseLiveChat.heading2') }}</p></div>
        </div>
      </div>
    </div> <!-- section-client-case-live-chat-heading2 -->

    <div class="section-client-case-live-chat-text2">
      <div class="row">
        <div class="col-12">
          <div class="cc-text"><p style="white-space:pre-wrap;text-align:justify;text-justify:inter-word;">{{ $t('clientCaseLiveChat.text2') }}</p></div>
        </div>
      </div>
    </div> <!-- section-client-case-live-chat-text2 -->

    <div class="section-client-case-live-chat-image">
      <div class="row">
        <div class="col-12">
          <div class="cc-image">
<img :src="require('../assets'+$t('clientCaseLiveChat.image'))" :srcset="require('../assets'+$t('clientCaseLiveChat.image2x')) + ' 2x, ' + require('../assets'+$t('clientCaseLiveChat.image3x')) + ' 3x'" class="img-fluid">
          </div>
        </div>
      </div>
    </div> <!-- section-client-case-live-chat-image -->

  </div>
</template>

<script>
import Pointer from './Pointer'
//import BackgroundPattern from './BackgroundPattern'

export default {
  name: 'SectionClientCaseNameTag',

  components:{
    Pointer,
//    BackgroundPattern,
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.section-client-case-live-chat-pointer {
    margin-top:40px;
}
</style>
