<template>
  <div class="section-clients" id="clients">

        <BackgroundPattern id="section-clients-background-pattern-text-gallery" :text="$t('clients.pattern')" align="start"></BackgroundPattern>

    <BackgroundPattern id="section-clients-background-pattern-donut-right-xl" type="donut" align="right"></BackgroundPattern>

<b-container>

<b-col offset-md="1" md="10">

    <div class="section-clients-pointer" style="margin-top: 120px;">
      <div class="row">
        <div class="col-12">
          <Pointer></Pointer>
        </div>
      </div>
    </div> <!-- section-clients-pointer -->

    <div class="section-clients-title">
      <div class="row">
        <div class="col-12">
<div data-v-789dec86="" class="justify-content-center">
          <div class="section-title"><p style="white-space:pre-wrap;">{{ $t('clients.title') }}</p></div>
</div>
        </div>
      </div>
    </div> <!-- section-clients-title -->


    <div class="section-clients-filter">
<MediaFilter/>
    </div> <!-- section-clients-filter -->

    <div class="section-clients-media">






    <div class="item client-brand-building">
      <div class="well"> 
        <div class="video-container">
          <b-embed type="iframe" aspect="embed-responsive-4by3" src="https://www.youtube.com/embed/ye4aCqNb5K4" allowfullscreen></b-embed>
        </div>
      </div>
    </div>

    <div class="item client-profile">
      <div class="well"> 
        <div class="video-container">
          <b-embed type="iframe" aspect="embed-responsive-4by3" src="https://www.youtube.com/embed/TZLyN5ims5A" allowfullscreen></b-embed>
        </div>
      </div>
    </div>

    <div class="item client-documentary">
      <div class="well"> 
        <div class="video-container">
          <b-embed type="iframe" aspect="embed-responsive-4by3" src="https://www.youtube.com/embed/-7DrKXTYuyc" allowfullscreen></b-embed>
        </div>
      </div>
    </div>

    <div class="item client-live-virtual-events">
      <div class="well"> 
        <div class="video-container">
          <b-embed type="iframe" aspect="embed-responsive-4by3" src="https://www.youtube.com/embed/11G_Yq8yJC4" allowfullscreen></b-embed>
        </div>
      </div>
    </div>

    <div class="item client-selflab">
      <div class="well"> 
        <div class="video-container">
          <b-embed type="iframe" aspect="embed-responsive-4by3" src="https://www.youtube.com/embed/60yKTKwF584" allowfullscreen></b-embed>
        </div>
      </div>
    </div>

    <div class="item client-selflab">
      <div class="well"> 
        <b-img :src="require('../assets/img/clients/self-lab-01.jpg')" fluid></b-img>
      </div>
    </div>
    <div class="item client-selflab">
      <div class="well"> 
        <b-img :src="require('../assets/img/clients/self-lab-02.jpg')" fluid></b-img>
      </div>
    </div>
    <div class="item client-selflab">
      <div class="well"> 
        <b-img :src="require('../assets/img/clients/self-lab-03.jpg')" fluid></b-img>
      </div>
    </div>

    <div class="item client-mvs client-mvs-con">
      <div class="well"> 
        <b-img :src="require('../assets/img/clients/mvs-con-01.png')" fluid></b-img>
      </div>
    </div>
    <div class="item client-mvs client-mvs-con">
      <div class="well"> 
        <b-img :src="require('../assets/img/clients/mvs-con-02.png')" fluid></b-img>
      </div>
    </div>
    <div class="item client-mvs client-mvs-con">
      <div class="well"> 
        <b-img :src="require('../assets/img/clients/mvs-con-03.png')" fluid></b-img>
      </div>
    </div>

    <div class="item client-mvs client-mvs-fa1">
      <div class="well"> 
        <b-img :src="require('../assets/img/clients/mvs-fa1-01.png')" fluid></b-img>
      </div>
    </div>
    <div class="item client-mvs client-mvs-fa1">
      <div class="well"> 
        <b-img :src="require('../assets/img/clients/mvs-fa1-02.png')" fluid></b-img>
      </div>
    </div>
    <div class="item client-mvs client-mvs-fa1">
      <div class="well"> 
        <b-img :src="require('../assets/img/clients/mvs-fa1-03.png')" fluid></b-img>
      </div>
    </div>
    <div class="item client-mvs client-mvs-fa1">
      <div class="well"> 
        <b-img :src="require('../assets/img/clients/mvs-fa1-04.png')" fluid></b-img>
      </div>
    </div>

    <div class="item client-mvs client-mvs-fa2">
      <div class="well"> 
        <b-img :src="require('../assets/img/clients/mvs-fa2-01.png')" fluid></b-img>
      </div>
    </div>
    <div class="item client-mvs client-mvs-fa2">
      <div class="well"> 
        <b-img :src="require('../assets/img/clients/mvs-fa2-02.png')" fluid></b-img>
      </div>
    </div>
    <div class="item client-mvs client-mvs-fa2">
      <div class="well"> 
        <b-img :src="require('../assets/img/clients/mvs-fa2-03.png')" fluid></b-img>
      </div>
    </div>

    <div class="item client-mvs client-mvs-hm">
      <div class="well"> 
        <b-img :src="require('../assets/img/clients/mvs-hm-01.png')" fluid></b-img>
      </div>
    </div>
    <div class="item client-mvs client-mvs-hm">
      <div class="well"> 
        <b-img :src="require('../assets/img/clients/mvs-hm-02.png')" fluid></b-img>
      </div>
    </div>
    <div class="item client-mvs client-mvs-hm">
      <div class="well"> 
        <b-img :src="require('../assets/img/clients/mvs-hm-03.png')" fluid></b-img>
      </div>
    </div>
    <div class="item client-mvs client-mvs-hm">
      <div class="well"> 
        <b-img :src="require('../assets/img/clients/mvs-hm-04.png')" fluid></b-img>
      </div>
    </div>

    <div class="item client-mvs client-mvs-moa">
      <div class="well"> 
        <b-img :src="require('../assets/img/clients/mvs-moa-01.jpg')" fluid></b-img>
      </div>
    </div>
    <div class="item client-mvs client-mvs-moa">
      <div class="well"> 
        <b-img :src="require('../assets/img/clients/mvs-moa-02.png')" fluid></b-img>
      </div>
    </div>
    <div class="item client-mvs client-mvs-moa">
      <div class="well"> 
        <b-img :src="require('../assets/img/clients/mvs-moa-03.png')" fluid></b-img>
      </div>
    </div>

    <div class="item client-mvs client-mvs-oi">
      <div class="well"> 
        <b-img :src="require('../assets/img/clients/mvs-oi-01.jpg')" fluid></b-img>
      </div>
    </div>
    <div class="item client-mvs client-mvs-oi">
      <div class="well"> 
        <b-img :src="require('../assets/img/clients/mvs-oi-02.png')" fluid></b-img>
      </div>
    </div>
    <div class="item client-mvs client-mvs-oi">
      <div class="well"> 
        <b-img :src="require('../assets/img/clients/mvs-oi-03.png')" fluid></b-img>
      </div>
    </div>
    <div class="item client-mvs client-mvs-oi">
      <div class="well"> 
        <b-img :src="require('../assets/img/clients/mvs-oi-04.png')" fluid></b-img>
      </div>
    </div>
    <div class="item client-mvs client-mvs-oi">
      <div class="well"> 
        <b-img :src="require('../assets/img/clients/mvs-oi-05.png')" fluid></b-img>
      </div>
    </div>

    <div class="item client-mvs client-mvs-scm1">
      <div class="well"> 
        <b-img :src="require('../assets/img/clients/mvs-scm1-01.jpg')" fluid></b-img>
      </div>
    </div>
    <div class="item client-mvs client-mvs-scm1">
      <div class="well"> 
        <b-img :src="require('../assets/img/clients/mvs-scm1-02.jpg')" fluid></b-img>
      </div>
    </div>
    <div class="item client-mvs client-mvs-scm1">
      <div class="well"> 
        <b-img :src="require('../assets/img/clients/mvs-scm1-03.jpg')" fluid></b-img>
      </div>
    </div>

    <div class="item client-mvs client-mvs-scm2">
      <div class="well"> 
        <b-img :src="require('../assets/img/clients/mvs-scm2-01.jpg')" fluid></b-img>
      </div>
    </div>
    <div class="item client-mvs client-mvs-scm2">
      <div class="well"> 
        <b-img :src="require('../assets/img/clients/mvs-scm2-02.jpg')" fluid></b-img>
      </div>
    </div>
    <div class="item client-mvs client-mvs-scm2">
      <div class="well"> 
        <b-img :src="require('../assets/img/clients/mvs-scm2-03.png')" fluid></b-img>
      </div>
    </div>

    <div class="item client-mvs client-mvs-spm1">
      <div class="well"> 
        <b-img :src="require('../assets/img/clients/mvs-spm1-01.png')" fluid></b-img>
      </div>
    </div>
    <div class="item client-mvs client-mvs-spm1">
      <div class="well"> 
        <b-img :src="require('../assets/img/clients/mvs-spm1-02.png')" fluid></b-img>
      </div>
    </div>
    <div class="item client-mvs client-mvs-spm1">
      <div class="well"> 
        <b-img :src="require('../assets/img/clients/mvs-spm1-03.png')" fluid></b-img>
      </div>
    </div>

    <div class="item client-mvs client-mvs-spm2">
      <div class="well"> 
        <b-img :src="require('../assets/img/clients/mvs-spm2-01.png')" fluid></b-img>
      </div>
    </div>
    <div class="item client-mvs client-mvs-spm2">
      <div class="well"> 
        <b-img :src="require('../assets/img/clients/mvs-spm2-02.png')" fluid></b-img>
      </div>
    </div>
    <div class="item client-mvs client-mvs-spm2">
      <div class="well"> 
        <b-img :src="require('../assets/img/clients/mvs-spm2-03.png')" fluid></b-img>
      </div>
    </div>


    <div class="item client-mvs client-mvs-mh">
      <div class="well"> 
        <b-img :src="require('../assets/img/clients/mvs-mh-01.png')" fluid></b-img>
      </div>
    </div>
    <div class="item client-mvs client-mvs-mh">
      <div class="well"> 
        <b-img :src="require('../assets/img/clients/mvs-mh-02.png')" fluid></b-img>
      </div>
    </div>
    <div class="item client-mvs client-mvs-mh">
      <div class="well"> 
        <b-img :src="require('../assets/img/clients/mvs-mh-03.png')" fluid></b-img>
      </div>
    </div>
    <div class="item client-mvs client-mvs-mh">
      <div class="well"> 
        <b-img :src="require('../assets/img/clients/mvs-mh-04.png')" fluid></b-img>
      </div>
    </div>
    <div class="item client-mvs client-mvs-mh">
      <div class="well"> 
        <b-img :src="require('../assets/img/clients/mvs-mh-05.png')" fluid></b-img>
      </div>
    </div>


    <div class="item client-kc-imagery">
      <div class="well"> 
        <div class="video-container">
          <b-embed type="iframe" aspect="embed-responsive-4by3" src="https://www.youtube.com/embed/xkwmP3ERn58" allowfullscreen></b-embed>
        </div>
      </div>
    </div>
    <div class="item client-kc-imagery">
      <div class="well"> 
        <b-img :src="require('../assets/img/clients/kc-imagery-01.jpg')" fluid></b-img>
      </div>
    </div>
    <div class="item client-kc-imagery">
      <div class="well"> 
        <b-img :src="require('../assets/img/clients/kc-imagery-02.jpg')" fluid></b-img>
      </div>
    </div>
    <div class="item client-kc-imagery">
      <div class="well"> 
        <b-img :src="require('../assets/img/clients/kc-imagery-03.jpg')" fluid></b-img>
      </div>
    </div>
    <div class="item client-kc-imagery">
      <div class="well"> 
        <b-img :src="require('../assets/img/clients/kc-imagery-04.jpg')" fluid></b-img>
      </div>
    </div>

    <div class="item client-boomato">
      <div class="well"> 
        <b-img :src="require('../assets/img/clients/boomato-01.jpg')" fluid></b-img>
      </div>
    </div>
    <div class="item client-boomato">
      <div class="well"> 
        <b-img :src="require('../assets/img/clients/boomato-02.jpg')" fluid></b-img>
      </div>
    </div>
    <div class="item client-boomato">
      <div class="well"> 
        <b-img :src="require('../assets/img/clients/boomato-03.jpg')" fluid></b-img>
      </div>
    </div>
    <div class="item client-boomato">
      <div class="well"> 
        <b-img :src="require('../assets/img/clients/boomato-04.jpg')" fluid></b-img>
      </div>
    </div>
    <div class="item client-boomato">
      <div class="well"> 
        <b-img :src="require('../assets/img/clients/boomato-05.jpg')" fluid></b-img>
      </div>
    </div>
    <div class="item client-boomato">
      <div class="well"> 
        <b-img :src="require('../assets/img/clients/boomato-06.jpg')" fluid></b-img>
      </div>
    </div>
    <div class="item client-boomato">
      <div class="well"> 
        <b-img :src="require('../assets/img/clients/boomato-07.jpg')" fluid></b-img>
      </div>
    </div>
    <div class="item client-boomato">
      <div class="well"> 
        <b-img :src="require('../assets/img/clients/boomato-08.jpg')" fluid></b-img>
      </div>
    </div>
    <div class="item client-boomato">
      <div class="well"> 
        <b-img :src="require('../assets/img/clients/boomato-09.jpg')" fluid></b-img>
      </div>
    </div>
    <div class="item client-boomato">
      <div class="well"> 
        <b-img :src="require('../assets/img/clients/boomato-10.jpg')" fluid></b-img>
      </div>
    </div>

</div>




</b-col>

</b-container>

    <BackgroundPattern id="section-clients-background-pattern-donut-right-xs" type="donut" align="right"></BackgroundPattern>

  </div>
</template>

<script>
import Pointer from './Pointer'
import BackgroundPattern from './BackgroundPattern'
import MediaFilter from './MediaFilter'

export default {
  name: 'SectionClients',

  components:{
    Pointer,
    BackgroundPattern,
    MediaFilter,
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>




.video-container {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;

-webkit-mask-image: -webkit-radial-gradient(circle, white 100%, black 100%); /*ios 7 border-radius-bug */
-webkit-transform: rotate(0.000001deg); /*mac os 10.6 safari 5 border-radius-bug */
-webkit-border-radius: 10px; 
-moz-border-radius: 10px;
border-radius: 10px; 
overflow: hidden; 

}

.video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}






.section-clients-media {
 -moz-column-width: 18em;
 -webkit-column-width: 18em;
 -moz-column-gap: 1em;
 -webkit-column-gap:1em; 
  
}

.item {
 display: inline-block;
 padding:  .25rem;
 width:  100%; 
}

.well {
 position:relative;
 display: block;
}

.section-clients-media .item .well img {
  border-radius: 5%;
  width:100%;
}





.client-case-text {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #50534a;
text-decoration: none;
margin: 10px 10px;
}

.client-case-text:focus, .client-case-text:hover {
    z-index: 1;
    text-decoration: none;
color: #da291c;
/*
    color: #495057;
    background-color: #f8f9fa;
*/
}

.client-case-pill {
  margin:12px;
  padding: 20px 20px;
  border-radius: 26px;
  box-shadow: 20px 20px 40px 0 rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;

}


.section-clients-title .section-title {
    width: fit-content;
margin-left: auto;
    margin-right: auto;
}

.section-clients-filter .media-filter-client {
    width: fit-content;
margin-left: auto;
    margin-right: auto;
}

.card-columns {
column-count:3;
}

</style>
