<template>
  <div class="section-client-case-live-poll">

    <div class="section-client-case-live-poll-pointer">
      <div class="row">
        <div class="col-12">
          <Pointer></Pointer>
        </div>
      </div>
    </div> <!-- section-client-case-live-poll-pointer -->

    <div class="section-client-case-live-poll-title">
      <div class="row">
        <div class="col-12">
          <div class="cc-title"><p style="white-space:pre-wrap;">{{ $t('clientCaseLivePoll.title') }}</p></div>
        </div>
      </div>
    </div> <!-- section-client-case-live-poll-title -->

    <div class="section-client-case-live-poll-headline">
      <div class="row">
        <div class="col-12">
          <div class="cc-headline"><p style="white-space:pre-wrap;">{{ $t('clientCaseLivePoll.headline') }}</p></div>
        </div>
      </div>
    </div> <!-- section-client-case-live-poll-headline -->

    <div class="section-client-case-live-poll-heading">
      <div class="row">
        <div class="col-12">
          <div class="cc-heading"><p style="white-space:pre-wrap;">{{ $t('clientCaseLivePoll.heading') }}</p></div>
        </div>
      </div>
    </div> <!-- section-client-case-live-poll-heading -->

    <div class="section-client-case-live-poll-text">
      <div class="row">
        <div class="col-12">
          <div class="cc-text"><p style="white-space:pre-wrap;text-align:justify;text-justify:inter-word;">{{ $t('clientCaseLivePoll.text') }}</p></div>
        </div>
      </div>
    </div> <!-- section-client-case-live-poll-text -->

    <div class="section-client-case-live-poll-image">
      <div class="row">
        <div class="col-12">
          <div class="cc-image">
<img :src="require('../assets'+$t('clientCaseLivePoll.image'))" :srcset="require('../assets'+$t('clientCaseLivePoll.image2x')) + ' 2x, ' + require('../assets'+$t('clientCaseLivePoll.image3x')) + ' 3x'" class="img-fluid">
          </div>
        </div>
      </div>
    </div> <!-- section-client-case-live-poll-image -->

  </div>
</template>

<script>
import Pointer from './Pointer'
//import BackgroundPattern from './BackgroundPattern'

export default {
  name: 'SectionClientCaseLivePoll',

  components:{
    Pointer,
//    BackgroundPattern,
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.section-client-case-live-poll-pointer {
    margin-top:40px;
}
</style>
